import React from "react";
import Modal from "react-modal";
import { useState } from "react";
import { CustomButton } from "../../../../components/CustomButton";
import { useMobile } from "../../../../hooks";

function MyModal({
  type = "",
  isOpen,
  setisopen,
  name = "",
  Title = "",
  text = "",
  OkText = "Sure",
  CancelText = "Cancel",
  isLoading,
  OkFunction,
  children,
  CloseFunction,
  width = null,
}) {
  const isMobile = useMobile();
  const customStyles = {
    content: isMobile
      ? {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          maxHeight: "90vh",
          overflowY: "scroll",
          padding: "10px",
          overflowX: "hidden",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          borderTop: "rgba(161, 129, 58, 1) solid 6px",
          display: "flex",
          justifyContent: "center",
          width: width ? width : isMobile ? "85%" : "40%",
        }
      : {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          maxHeight: "90vh",
          overflowY: "scroll",
          overflowX: "hidden",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          borderTop: "rgba(161, 129, 58, 1) solid 6px",
          display: "flex",
          justifyContent: "center",
          width: width ? width : isMobile ? "85%" : "40%",
        },
    overlay: {
      backgroundColor: "rgba(24, 50, 78, 0.9)",
      zIndex: 1001,
    },
  };

  if (!isOpen) {
    return <></>;
  } else {
    return (
      <>
        <Modal
          isOpen={isOpen}
          style={customStyles}
          onRequestClose={async () => {
            setisopen(false);
            await CloseFunction();
          }}
        >
          <div style={{ position: "absolute", right: "20px" }}>
            <div>{name}</div>
            <svg
              onClick={() => {
                setisopen(false);
                CloseFunction();
              }}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </div>
          {children ? (
            children
          ) : (
            <div>
              {" "}
              <div
                style={{
                  textAlign: "center",
                  marginTop: "-20px",
                  paddingBottom: "10px",
                }}
              >
                {type == "success" ? (
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginTop: "20px" }}
                  >
                    <path
                      d="M18 33C22.1421 33 25.8921 31.3211 28.6066 28.6066C31.3211 25.8921 33 22.1421 33 18C33 13.8579 31.3211 10.1079 28.6066 7.39339C25.8921 4.67893 22.1421 3 18 3C13.8579 3 10.1079 4.67893 7.39339 7.39339C4.67893 10.1079 3 13.8579 3 18C3 22.1421 4.67893 25.8921 7.39339 28.6066C10.1079 31.3211 13.8579 33 18 33Z"
                      fill="white"
                      stroke="#248C00"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 18L16 23L26 13"
                      stroke="#248C00"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : type == "question" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="rgba(161, 129, 58, 1) "
                    class="bi bi-question-circle"
                    viewBox="0 0 16 16"
                    style={{ marginTop: "20px" }}
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                  </svg>
                ) : (
                  <></>
                )}
              </div>
              <p
                style={
                  isMobile
                    ? {
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingTop: "10px",
                        lineHeight: "16px",
                        paddingBottom: "15px",
                      }
                    : {
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "20px",
                        lineHeight: "24px",
                        paddingTop: "5px",
                        paddingBottom: "15px",
                      }
                }
              >
                {Title}
              </p>
              {text && (
                <p
                  style={
                    isMobile
                      ? {
                          margin: "auto",
                          textAlign: "center",
                          fontSize: "16px",
                          paddingTop: "10px",
                          width: "75%",
                          paddingBottom: "20px",
                        }
                      : {
                          margin: "auto",
                          textAlign: "center",
                          fontSize: "16px",
                          paddingTop: "10px",
                          width: "75%",
                          paddingBottom: "40px",
                        }
                  }
                >
                  {text}
                </p>
              )}
              {type == "question" ? (
                <div
                  style={
                    isMobile
                      ? { textAlign: "center", paddingBottom: "10px" }
                      : { textAlign: "center", paddingBottom: "40px" }
                  }
                >
                  <CustomButton
                    uiType="primary"
                    title={OkText}
                    additionalClassNames="col-5  mx-1"
                    onClick={OkFunction}
                    loading={isLoading}
                  />
                  <CustomButton
                    uiType="primary2"
                    title={CancelText}
                    additionalClassNames="col-5 mx-1"
                    onClick={() => {
                      setisopen(false);
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </Modal>
      </>
    );
  }
}

export default MyModal;
